import React from 'react'
import PropTypes from 'prop-types'
//import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
//import Img from "gatsby-image"
//import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Header from '../components/Template/Header'
//import Newsletter from '../components/Template/Newsletter'
import Footer from '../components/Template/Footer'

import bootstrap from '../css/bootstrap.min.module.css'
import styles from '../css/blog.module.css'
import custom from '../css/blog.custom.module.css'

export const BlogPostTemplate = ({
  content,
  contentComponent,
  date,
  author,
  featured_image,
  author_avatar,
  tags,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content

  return (
    <>
    {helmet || ''}
    <Header/>

    <div className={`${styles.siteCover} ${styles.siteCoverSm} ${styles.sameHeight} ${styles.overlay} ${styles.singlePage}`} style={{backgroundImage: `url('${featured_image}')`}}>
      <div className={bootstrap.container}>
        <div className={`${bootstrap.row} ${styles.sameHeight} ${bootstrap.justifyContentCenter}`}>
          <div className={`${bootstrap.colMd12} ${bootstrap.colLg10}`}>
            <div className={`${styles.postEntry} ${bootstrap.textCenter}`}>
              <h1 className={bootstrap.mb4}><a href="/#">{title}</a></h1>
              <div className={`${styles.postMeta} ${bootstrap.alignItemsCenter} ${bootstrap.textCenter}`}>
                <figure className={`${styles.authorFigure} ${bootstrap.mb0} ${bootstrap.mr3} ${bootstrap.dInlineBlock}`}>
                <img src={author_avatar} alt="author" className={bootstrap.imgFluid}/></figure>
                <span className={`${bootstrap.dInlineBlock} ${bootstrap.mt1}`}>By {author}</span>
                <span>&nbsp;-&nbsp; {date}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section className={`${styles.siteSection} ${bootstrap.pyLg}`}>
      <div className={bootstrap.container}>
        
        <div className={`${bootstrap.row} ${styles.blogEntries} ${styles.elementAnimate} ${bootstrap.justifyContentCenter}`}>

          <div className={`${bootstrap.colMd12} ${bootstrap.colLg8} ${bootstrap.mainContent}`}>
            
          <div className={bootstrap.postContentBody}>
              <p className={custom.p}>
                <h1 className={styles.h1}>
                  {title}
                </h1>
              </p>
              {/*<p className={custom.p}>{description}</p>*/}
              <PostContent content={content} />
          </div>

          </div>


        </div>
      </div>
    </section>

    {/*<Newsletter/>*/}
    <Footer/>
    </>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  //description: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  author: PropTypes.string,
  helmet: PropTypes.object,
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        date={post.frontmatter.date}
        author={post.frontmatter.author}
        featured_image={post.frontmatter.featured_image}
        author_avatar={post.frontmatter.author_avatar}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        author
        featured_image
        author_avatar
      }
    }
  }
`
